<template>
	<div id='about'>
		<slide-show></slide-show>
		<Content></Content>
		<Introduce></Introduce>
	</div>
</template>
<script>
import SlideShow from "@/views/About/SlideShow"
import Content from "@/views/About/Content"

import Introduce from "@/views/About/Introduce"



export default {
	name: 'About',
	components: {
		SlideShow,
		Content,

		Introduce,
	}
}
</script>
<style scoped>
</style>