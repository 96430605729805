var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"main"},[_c('img',{attrs:{"src":this.mainImgUrl2}})]),_c('div',{staticClass:"thumb"},[_c('div',{staticClass:"thumb-container",style:('top:' + "-" + (this.thumbContainerTop) + "px;")},_vm._l((_vm.thumbImgs),function(img,index){return _c('div',{key:index,staticClass:"thumb-pic",style:(_vm.currThumbImg === index ? 'borderColor: rgb(132, 95, 63)' : ''),on:{"click":function($event){return _vm.changeMainImg(img, index)}}},[_c('img',{attrs:{"src":img}})])}),0),_c('div',{staticClass:"thumb-arrow-up",on:{"click":function () {
            var index = _vm.currThumbImg;
            var img;
            if (index > 0) {
              img = _vm.thumbImgs[index - 1];
              index = _vm.currThumbImg - 1;
              this$1.changeMainImg(img, index);
            }
          }}},[_c('i',{staticClass:"el-icon-caret-top"})]),_c('div',{staticClass:"thumb-arrow-down",on:{"click":function () {
            var index = _vm.currThumbImg;
            var img;
            if (_vm.currThumbImg < _vm.thumbImgs.length - 1) {
              img = _vm.thumbImgs[index + 1];
              index = _vm.currThumbImg + 1;
              this$1.changeMainImg(img, index);
            }
          }}},[_c('i',{staticClass:"el-icon-caret-bottom"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }