<template>
	<div id='news'>
		<MainNews></MainNews>
	</div>
</template>
<script>
import MainNews from "@/components/content/MainNews"

export default {
	name: 'News',
	components: {
		MainNews
	}
}
</script>
<style scoped>
</style>