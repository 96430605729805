<template>
	<NewsBg ref='container'>
		<NewsLeft>
			<slot slot='date'>6月14 2021</slot>
		</NewsLeft>
		<NewsRight>
			<slot slot='date'>6月13 2021</slot>
			<slot slot='title'>贵州铜仁：龙舟巡游迎端午</slot>
			<slot slot='content'>
				<span>6月12日，人们在贵州省铜仁市碧江区锦江河上举行龙舟巡游展演活动（无人机拍摄）。当日，2019年中国龙舟公开赛（贵州·铜仁·碧江站）在中国传统龙舟之乡铜仁市碧江区拉开序幕，200余艘船只在锦江河上举行大规模巡游展演，迎接端午节的到来。</span>
			</slot>
			<slot slot='img'>
				<img alt='' src='~assets/images/News/1.jpg'>
			</slot>
		</NewsRight>
		<NewsLeft>
			<slot slot='date'>6月11 2021</slot>
			<slot slot='title'>四川青神：百舟竞渡迎端午</slot>
			<slot slot='content'>
				<span>6月5日上午，来自四川省各市州的18支龙舟队驶入岷江汉阳湖水域，四川省2019“百舟竞渡迎端午”集中展演——四川青神龙舟会就此拉开帷幕。此次龙舟会包括22人龙舟500米直道竞速和“抢鸭子”两个项目。经过激烈角逐，眉山市龙舟队夺得竞速冠军。随后的抢鸭子大赛也博得沿岸观众阵阵欢呼。</span>
			</slot>
			<slot slot='img'>
				<img alt='' src='~assets/images/News/2.jpg'>
			</slot>
		</NewsLeft>
		<NewsRight>
			<slot slot='date'>6月13 2021</slot>
			<slot slot='title'>家国情怀是端午节最美底色</slot>
			<slot slot='content'><span>一年一度的端午节又到了。这个节日，不只有粽香扑鼻和龙舟竞渡，更有一种人格魅力被千古传颂，有一种民族精神亘古流传。</span>
				　　<span>公元前278年农历五月初五，在汨罗江岸，一位古稀之年的老人因不堪忍受国破家亡的惨象，抱着一块石头投入万顷碧波，愤然与世长辞。他的纵身一跃，消逝的只是肉体，诠释的文化和精神却如一个个跳跃的音符，被千年传唱。“屈心而抑志兮，忍尤而攘诟。伏清白以死直兮，固前圣之所厚”，道出多少殉国之志。视死如归的背后，那份浓重的家国情怀跃然纸上。</span>
				　<span>　今天，我们纪念屈原，不只是缅怀一个文化符号。更重要的是，我们要在缅怀中感受一种穿越时空永恒的精神力量。两千多年时光荏苒，历史长河滚滚向前，端午节一直承载着历史记忆和精神内涵，让家国情怀成为传统节日最美的底色，根植于每一个人心中。</span>
				　　<span>“在社会主义核心价值观中，最深层、最根本、最永恒的是爱国主义。爱国主义是常写常新的主题。”爱国是最大公约数，有着几千年中华文化血脉的滋养。屈原、苏武、辛弃疾、岳飞、魏源、邓世昌、焦裕禄、孔繁森等等，历史星空浩淼，但那些心怀天下、忧国忧民的人们，总能扣人心弦，为人们所深刻铭记。不管时代如何变化，传统文化的精神内核总不变；不管岁月如何更迭，家国情怀总能赋予时代前行的强大动能。</span>
				　<span>　一个时代有一个时代的主题，一代人有一代人的使命。今天，新时代的爱国青年和仁人志士们，又给予了家国情怀最美的时代注脚。戴着呼吸机工作到生命最后一刻的林俊德；为扑灭凉山火灾而葬身火海的31名扑火人员；一声“你退后，让我来！”危难时刻冲锋在前、信仰坚定、为民奉献的扫雷英雄杜富国；60多年深藏功名，一辈子坚守初心的老英雄张富清……他们身上闪烁着的何尝不是流传千年的精神，何尝不是把小我融入大家的情怀？</span>
				　　<span>不仅如此，端午节也是一扇体味“我和我的祖国”的最美窗口。1953年，屈原被联合国定为世界四大文化名人之一；2009年，端午节成为世界非物质文化遗产，是我国首个入选世界非物质文化遗产的节日；龙舟竞赛已经在世界多国落地生根……回眸上下五千年中华文化，体味新中国成立70周年以来中华之崛起，我们不难体会，家国情怀永不过时，永远是实现国家富强、民族复兴、人民幸福的最强动能。</span>
				　<span>　家是最小国，国是千万家。看似普通的端午节，却蕴含着伟大的家国情怀。就让我们以端午节为契机，追思爱国的民族先贤，传承永恒的家国情怀，为国家前行汇聚起源源不断的精神力量，创造更美好的明天，书写更精彩的未来。（特约评论员胡辉）</span>
			</slot>
			<slot slot='img'>
				<img alt='' src='~assets/images/News/3.jpg'>
			</slot>
		</NewsRight>
		<NewsLeft v-if='isMore'>
			<slot slot='date'>6月8 2021</slot>
			<slot slot='title'>品端午民俗 享欢乐假期</slot>
			<slot slot='content'><span>央视网消息（新闻联播）：今天是端午假期的第二天，人们或举家出游，或体验民俗，在丰富多彩的活动当中，尽享欢乐假期。</span>
				<span>端午假期最热闹的民俗，当属赛龙舟了。今天，在北京顺义的奥林匹克水上公园，来自俄罗斯、菲律宾等国的龙舟爱好者激情挥桨。在四川自贡、甘肃敦煌、山西沁县、黑龙江哈尔滨，一艘艘龙舟劈波斩浪，一比高下。</span>
				<span>在福建石狮，“一年一度”的海上泼水节热闹非凡，人们在渔船上追逐泼水，祈求平安兴旺。在重庆铜梁安居古城，手工扎制的“水龙”走街串巷，沿街群众向水龙泼水祈福，一时间，水龙翻腾、水花四溅。“洗龙水”是海南的端午习俗，寓意健康平安。海口“洗龙水”的市民和游客超过20万人次。</span>
				<span>端午假期，北京世园会成热门“打卡地”，昨天接待游客超过5万人次。场馆采取限流措施，引导游客分批入馆。</span>
				<span>这两天，各大景区游客增长明显，山东泰山、安徽黄山、贵州梵净山等迎来客流高峰，各大景区周边道路通行压力增大。全国铁路今天加开旅客列车540列，主要是以中短途为主。</span>
				<span>这个端午假期，文明新风到处可见。江苏扬州推行“长者先动筷、夹菜用公筷”的用餐礼仪，弘扬敬老爱老的传统美德，倡导文明用餐。</span>
				<span>在浙江绍兴嵊州，20支志愿者队伍走上街头，开展志愿服务。这些志愿者有的是左右邻居，有的是一家大人孩子齐上阵。</span>
			</slot>
			<slot slot='img'>
				<img alt='' src='~assets/images/News/2.gif'>
			</slot>
		</NewsLeft>
		<NewsRight v-if='isMore'>
			<slot slot='date'>6月7 2021</slot>
			<slot slot='title'>赏民俗 品文化——各地欢庆端午节</slot>
			<slot slot='content'><span>央视网消息（新闻联播）：今天（7日）是我国传统节日端午节。各地群众赛龙舟、包粽子、赏民俗，欢度佳节的同时，传承传统文化，弘扬家国情怀。</span>
				<span>锣鼓响，号子齐。端午节里，最热闹的当属龙舟赛。</span>
				<span>在福建福州，2019年中华龙舟大赛福州站今天进行了决赛，共有超过60支龙舟队、近2000名队员参赛，全部十六个冠军各有归属，其中聊城大学队夺得6项冠军。此外，福州站特别增设的36人传统龙舟赛的冠军被高新区建平队夺得。</span>
				<span>在广西南宁，国际龙舟邀请赛为市民献上一场“体育+民俗”的节日盛宴；在深圳市民自发的社区龙舟赛别有新意。</span>
				<span>龙舟赛是比赛，也是纪念，在屈原故里湖北秭归，划龙舟、祭先贤的传统已经延续了千年。</span>
				<span>在四川眉山岷江两岸，百舸争流，劈波斩浪；在河南新乡的高山峡谷间，龙舟竞渡，勇往直前；在海南乐东南海之滨，龙舟健儿激情挥桨，搏击大海。</span>
				<span>端午佳节，粽叶飘香。在陕西榆林、贵州长顺，上千人的包粽子大赛热闹非凡；安徽颍上的社区百姓共度端午，文明和谐；在新疆塔城哈尔墩社区，14个民族居民拿出各自的美食，聚在一起拉家常、聊民俗。</span>
				<span>今天（7日），一些独特的民俗活动也在各地上演，在浙江宁波，来自台湾高雄、南投以及当地的80位两岸民间艺术家以亮绝活、话传承的特殊方式，带来原汁原味的民间艺术，共度端午佳节。</span>
				<span>在甘肃舟曲县，采花节是这里独特的端午民俗，摘一朵达玛花，跳一段朵迪舞，山歌对唱、祈福纳祥；吉林延吉的朝鲜族群众，今天（7日）聚集在一起，跳民族舞，举行体育比赛，他们用自己独特的方式，庆祝着端午佳节。</span>
			</slot>
			<slot slot='img'>
				<img alt='' src='~assets/images/News/3.gif'>
			</slot>
		</NewsRight>
		<NewsLeft v-if='isMore'>
			<slot slot='date'>6月6 2021</slot>
			<slot slot='title'>亚洲文明联展：端午佳节中的文化盛宴</slot>
			<slot slot='content'>
				<span>央视网消息（新闻联播）：端午佳节，正在举办的“亚洲文明联展”吸引众多观众走进博物馆。“大美亚细亚——亚洲文明展”自5月13日开幕至今，参观人数已突破40万人次。</span>
				<span>本周最后一批37件来自叙利亚的珍贵文物也正式和观众见面，逾万年历史的女性石雕像，3000多年历史的楔形文字泥版，为我们描绘出两河流域的古老文明图景。“殊方共享——丝绸之路文物精品展”也同期在国家博物馆举行，俄罗斯的叶形铜矛，哈萨克斯坦的黄金武士，中国汉代的博望侯封泥，来自“一带一路”沿线13个国家的234件（套）珍贵文物让观众大饱眼福。正在清华大学艺术博物馆展出的阿富汗珍宝展，最大亮点是璀璨夺目的众多金器，这些文物时间跨度4000余年，再现了阿富汗公元前3世纪至公元1世纪多彩的文明图景。亚洲文明展将一直持续到8月11日，预计参观人数将突破百万人次。</span>
			</slot>
			<slot slot='img'>
				<img alt='' src='~assets/images/News/4.gif'>
			</slot>
		</NewsLeft>
		<NewsRight v-if='isMore'>
			<slot slot='date'>6月5 2021</slot>
			<slot slot='title'>儿时的端午：吃粽子、撞蛋……你还记得哪些？</slot>
			<slot slot='content'>
				<span>中新网北京6月7日电(记者 上官云)“碧艾香蒲处处忙。谁家儿共女，庆端阳。”这句词里的“端阳”，说的便是端午节。</span>
				　　<span>从古至今，中国人过端午节的方式渐渐发生了些许变化。在一些人的童年记忆中，端午节就是要吃粽子、赛龙舟；对另一些人而言，还有做香包、插艾草……一事一物，全都浸透着浓浓的温情。</span>
				　　<span>“粽子？自己包的才好吃”</span>
				　<span>如果问端午节的“网红食品”是啥，十个人里大概会有一多半的回答是“粽子”。</span>
				　<span>准备粽子，是许多孩子们记忆中最期待的事。在全国不同地方，一个粽子可以包出各种不同的造型，比如有一种锥形长粽子，一角尖长，更耗费粽叶和馅料。</span>
				<span>每年离端午节还有几天时，王莉的妈妈就开始置办包粽子的材料，除了糯米、红枣之外，有时还会放一点花生和红豆，粽叶则多半是亲戚之间互相送。</span>
				　　<span>特地为端午节包的粽子，总是带着一些温情。正式开工前，红豆和糯米先要先用清水浸泡2小时；这个时候粽叶早已经洗干净，放进锅里一煮，满屋都是粽叶特有的清香。</span>
				　　<span>两片粽叶，一把糯米，再加上几粒红枣，不过一两分钟时间，一个有棱有角的粽子就包好了。王莉说，煮熟后，咬一口粽子里的红枣，幸福感简直爆棚。</span>
				　　<span>“长年累月地看着，我也会一点。”不过，王莉说，这些年家里很少自己包粽子，都是偷懒买来吃，“太忙了。”</span>
				　<span>‘撞蛋’是端午最好玩的游戏”</span>
				　　<span>王烨来自温州，对她而言，记忆中儿时的端午，是有趣的“撞蛋”游戏。</span>
				　　<span>“我家乡那边，端午节除了粽子外，还会吃咸鸭蛋或者茶叶蛋。”端午还没到，王烨的妈妈便会买回鸡蛋和鸭蛋，煮熟备用。</span>
				　　<span>在煮蛋的时候，还要放一种当地特有的“香茅草”。当成品出锅时，也会带上一种香味。王烨说不清那到底是什么味道，“反正挺好闻”。</span>
				　　<span>除了做成咸蛋，妈妈会给王烨留几个熟鸡蛋，供她玩“撞蛋”游戏。王烨会挑一个看上去皮厚点儿的，拿看上去比较结实的那一头去撞小伙伴的熟鸡蛋，蛋壳先被撞破的那一方为输。</span>
				　　<span>“每年大人都会给做一个‘蛋兜’，装上熟鸡蛋，挂在脖子上出去做游戏。”王烨说，蛋兜的样子像一个小网兜。她有一个红色的“蛋兜”，还带着一圈花边，好看极了。</span>
				<span>如今的端午，王烨还会照着习俗吃些咸鸭蛋或者茶叶蛋。在她的记忆中，端午不只是粽子，还有小伙伴和那个温馨的游戏。</span>
				　　<span>“门口插上艾草，就知道端午节到啦”</span>
				　　<span>在端午节的各类习俗中，“艾草”扮演着重要角色，古人认为，用艾草泡酒，饮之可以“祛邪”，又或者以“蒲龙艾虎”悬于门户禳毒气。</span>
				　　<span>小时候，每当端午节快到了，芦岩的奶奶都会提前去市场买艾草。她记得，新鲜的艾草最长的差不多得有一米，外观绿油油的，然后选一些插在门口。这个时候，就知道端午节到了。</span>
				　　<span>艾草也能用来做香料，放进“五彩丝线小粽子”里。芦岩说，这个粽子不是拿来吃的，而是小挂件，要用硬一点儿的纸做外壳，折成粽子的形状，然后用五彩丝线层层缠绕。</span>
				　　<span>再讲究一点的话，还可以在线绳上连缀一些小珠子。在她心里，这种自己手工做成的小项链，就是端午节的特色“首饰”。</span>
				　<span>端午节的记忆，可以不只是“吃粽子”</span>
				　　<span>对端午节，每个人可能会有不同的童年记忆。</span>
				　　<span>端午节最初并不是一个良辰吉日，此时天气炎热，容易令人感觉身体不适；蚊虫叮咬更易于造成疾病流行，人们为了驱邪避害会采取许多措施，“避瘟保健”是最早的节日主题。</span>
				　　<span>岁月流逝，端午节俗中增添了新的元素：汉魏六朝时期出现“先贤纪念”的主题。长江中游地区的“龙舟竞渡”，传说就是为了追悼屈原。而佩艾采药、斗草送扇等活动，具体在南北方又略有不同。</span>
				　　<span>在古代，人们将端午节视为社会交往的重要机会，多半会全面参与；现在，人们的生活节奏太快，在记者采访的十余人中，大多数对端午的记忆只剩“吃粽子”。其他习俗，似乎正在记忆中渐渐淡去。</span>
				　　<span>好的民俗文化值得传承。如专家所说，通过举办一系列民俗活动，端午节仍然可以成为人们情感交流、团结人际关系的机会。</span>
				　　<span>端午节的记忆，可以不只是吃粽子。(应受访者要求，部分为化名)(完)</span>
			</slot>
			<slot slot='img'>
				<img alt='' src='~assets/images/News/5.gif'>
			</slot>
		</NewsRight>
		<div class='more' @click='isShow'>
			<el-button v-loading="loading" type="primary">加载更多</el-button>
		</div>
	</NewsBg>
</template>
<script>
import NewsBg from '@/components/common/News/News-bg'
import NewsLeft from '@/components/common/News/News-left'
import NewsRight from '@/components/common/News/News-right'

export default {
	name: "MainNews",
	components: {NewsBg, NewsLeft, NewsRight},
	data() {
		return {
			isMore: false,
			loading: false
		}
	},
	methods: {
		isShow() {
			this.loading = true
			setTimeout(() => {
				let scroll = this.$refs.container.$el.scrollHeight + 250
				this.isMore = true
				this.$nextTick(() => {
					// console.log(this.$refs.container)
					document.documentElement.scrollTop = scroll
					this.loading = false
				})
			}, 1000)
		}
	}
}
</script>
<style scoped>
.more {
	margin: 40px 0;
	text-align: center;
}
</style>